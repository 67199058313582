<template>
  <div class="p-2 card">
  <b-overlay :show="loadingPage" rounded="lg" opacity="0.6">
    <b-row>
      <b-col
        md="12"
        lg="12">
        <h1>Cora - <span class="font-weight-light">Questionário</span></h1>
      </b-col>
    </b-row>
    <hr class="border-2">
    <div v-if="carregandoTabela" class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
      <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
      <span>Buscando dados...</span>
    </div>
    <section>
      <b-row class="my-2">
        <b-col md="2" class="mb-1 mb-md-0">
          <div class="d-flex flex-row align-items-center">
            <label class="mr-50">Visualizar</label>
            <b-form-select
              v-model="dadosPaginacao.quantidadeLinhasPadrao"
              size="sm"
              id="amounlinesVisualizer"
              label="option"
              @input="atualizaQuantidadeVisualizar($event)"
              :options="tabela.quantidadeLinhasVisualizar"
            />
          </div>
        </b-col>
        <b-col></b-col>
        <b-col md="3" class="d-flex justify-content-end">
          <b-form-input
            id="pesquisa"
            size="sm"
            v-model="busca.termo"
            autocomplete="off"
            placeholder="Buscar"
            @keyup="pesquisar"
          />
        </b-col>
        <b-col sm="12" class="my-2">
          <b-table
            id="listCompaniesTable"
            @context-changed="ordenarTabela"
            sticky-header="100%"
            :busy.sync="tabela.tabelaOcupada"
            :no-local-sorting="true"
            :fields="tabela.fields"
            :items="tabela.items"
          >
            <template #head(descricao)="data">
              <feather-icon icon="GridIcon" class="ml-2 mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
             <template #head(criacao)="data">
              <feather-icon icon="CalendarIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
            <template #head(situacao)="data">
              <feather-icon icon="LoaderIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
            <template #head(acoes)="data">
              <feather-icon icon="CheckCircleIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>

            <template #cell(descricao)="row">
              <div class="ml-2">
                {{ row.item.descricao }}
              </div>
            </template>

            <template #cell(criacao)="row">
              <div class="text-center">
                {{ moment(row.item.criacao, "YYYY-MM-DD").format('DD/MM/YYYY') }}
              </div>
            </template>
            <template #cell(situacao)="row">
               <div v-if="row.item.situacao === 'Ativo' || row.item.situacao === 'Inativo'" class="text-center">
                <b-form-checkbox
                  :checked="row.item.situacao === 'Ativo' ? true : false"
                  class="custom-control custom-control-success custom-control-label-switch"
                  name="check-button"
                  switch
                  @change="mudarStatus(row.item)"
                >
                  <span v-if="row.item.situacao === 'Ativo'" class="switch-label-ativo">
                    Ativo
                  </span>
                  <span v-else class="switch-label-inativo" >
                    Inativo
                  </span>
                </b-form-checkbox>
              </div>
              <div v-else class="text-center">
                <span
                  class="label-rascunho cursor-pointer"
                  @click="handleModalRascunho()"
                >
                  Rascunho
                </span>
              </div>
            </template>
            <template #cell(acoes)="row">
              <div class="text-center column-acoes">
                <b-button
                  @click="verDetalhes(row.item)"
                  variant="outline-primary"
                  class="margin-right-custom btn-icon btn-acao-tabela"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Visualizar e editar'"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  @click="duplicarQuestionario(row.item)"
                  :variant="row.item.situacao !== 'Ativo' ? 'outline-secondary' : 'outline-primary'"
                  :class="row.item.situacao !== 'Ativo'?   'margin-right-custom disabled btn-acao-tabela cursor-na':'margin-right-custom btn-icon btn-acao-tabela'"
                  :disabled="row.item.situacao !== 'Ativo'? true: false"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Duplicar'"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
                <b-button
                  @click="confirmarExcluirQuestionário(row.item)"
                  variant="outline-primary"
                  class="btn-icon btn-acao-tabela"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Excluir'"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col sm="12">
          <CustomPagination :paginacao="dadosPaginacao" @page-cliked="atualizarPaginaAtual"/>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BBadge,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BSpinner,
  BAlert,
  BIconPlusCircle,
  BIcon,
  BFormCheckbox,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue';
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import moment from 'moment';
import IconeInfo from "@/assets/custom-icons/info.svg";
import {
  modalGenericModel
} from '@/libs/sweetalerts';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BIconPlusCircle,
    BFormSelect,
    BButton,
    Cleave,
    BTable,
    CustomPagination,
    vSelect,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    moment,
    BIcon,
    BFormCheckbox,
    BOverlay,
    VBTooltip
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  setup() {
    return {
      IconeInfo
    };
  },

  data() {
    return {
      loadingPage: false,
      desabilitarBotao: false,
      busca: {
        termo: '',
      },
      atualizarRegistros: false,
      aplicacoes: [],
      carregandoTabela: true,
      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: '',
        campoOrdenado: '',
        fields: [
          {key: "descricao", label: "Questionário", sortable: true},
          {key: "criacao", label: "Data", sortable: true, class: 'w-25 text-center'},
          {key: "situacao", label: "Status", sortable: true, class: 'text-center'},
          {key: "acoes", label: "Ações", class: 'text-center text-center'}
        ],
        items: []
      },
    }
  },

  created() {
    this.buscarDadosTabela();
  },

  methods: {

    async verDetalhes(rowItem){
      this.loadingPage = true;
      let questionario = null;
      let rota = this.$helpers.preparaRotaComParametros(
            this.$api.questionario.retornaQuestionarioPorId,
            "{idQuestionario}",
            rowItem.id_questionario
          );

      await this.$http.get(rota).then((res) => {
        questionario = {
          idQuestionario:    res.data.id_questionario,
          descricao:         res.data.descricao,
          descricao_tecnica: res.data.descricao_tecnica,
          usarFeedback:      res.data.feedback ? true : false,
          qtdQuestoes:       res.data.questoes.length,
          questoes:          res.data.questoes,
          feedback:          res.data.feedback ? res.data.feedback : [],
          em_uso:            rowItem.aplicacao.length > 0 ? true : false,
          situacao:          rowItem.situacao,
        };
      });

      let route = 'editar-questionario';
      this.$router.push({ name: route , params: {questionarioData: questionario}});
      this.loadingPage = false;
    },

    novaAplicacao(rowItem){
      if(rowItem.situacao !== 'Rascunho'){
        this.$router.push({
          name: 'inserir-aplicacao',
          params: { questionarioData: rowItem }
        });
      }
    },

    async duplicarQuestionario(rowItem){
      modalGenericModel(
        'Duplicar questionário',
        'Deseja criar uma cópia deste questionário? O questionário será duplicado e' +
        ' você poderá alterar as informações nas ações de “Visualizar e Editar”',
        require('@/assets/custom-icons/cora-icons/copy.png'),
        'Sim, duplicar',
        'Cancelar'
      ).then((result) => {
        if (result.isConfirmed) {
          this.loadingPage = true;
          let rota =  this.$helpers.preparaRotaComParametros(
                        this.$api.questionario.duplicar,
                        "{idQuestionario}",
                        rowItem.id_questionario
                      );
          this.$http
              .get(rota)
              .then((res) => {
                if(res.status === 200){
                  this.buscarDadosTabela()
                  this.loadingPage = false;
                }
              })
              .catch((error) => {
                modalGenericModel(
                  error.response.data.error,
                  '',
                  require('@/assets/custom-icons/cora-icons/warning.png'),
                  'Entendi'
                )
                this.loadingPage = false;
              });
        }else{
          return;
        }
      });

    },

    confirmarExcluirQuestionário(rowItem){
      modalGenericModel(
        'Você tem certeza que deseja excluir o questionário criado? Essa ação não poderá ser desfeita!',
        '',
        require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
        'Excluir',
        'Cancelar'
      ).then((result) => {
        if (result.isConfirmed) {
          this.loadingPage = true;
          this.excluirQuestionario(rowItem);
        }else{
          return;
        }
      });
      this.loadingPage = false;
    },

    async excluirQuestionario(rowItem){
      this.loadingPage = true;
      let rota = this.$helpers.preparaRotaComParametros(
                    this.$api.questionario.deletar,
                    "{idQuestionario}",
                    rowItem.id_questionario
                  );
      await this.$http.delete(rota)
      .then((res) => {
        if(res.status === 200){
          this.buscarDadosTabela();
        }
      })
      .catch((error) => {
        modalGenericModel(
          error.response.data.error,
          '',
          require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
          'Entendi'
        )
      });
     this.loadingPage = false;
    },

    moment(date, format) {
      return moment(date, format);
    },

    pesquisar() {
      this.limparPaginacao();
      this.buscarDadosTabela();
    },

    buscarDadosTabela(firstTime = null) {
      if(firstTime && !this.mostrarTabela)
      {
        this.carregandoTabela = true;
        this.desabilitarBotao = true;
      }
      this.tabela.erroTabela = false;
      this.tabela.semDados = false;
      this.tabela.tabelaOcupada = true;
      this.$http.get(this.$api.questionario.questionarios, {params: this.lidarComParametrosBusca()})
      .then((response) => {
        this.desabilitarBotao = false;
        if(response.status === 200) {
          if(response.data.data.length > 0) {
            this.tabela.items = response.data.data;
            this.tabela.tabelaOcupada = false;
            this.carregandoTabela = false;
            this.IniciarPaginacao(response.data);
            return;
          }
          this.carregandoTabela = false;
          this.tabela.semDados = true;
          this.mostrarTabela = false;
        }
      }).catch((error) => {
        modalGenericModel(
          error.response.data.error,
          '',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Entendi'
        )
        this.desabilitarBotao = false;
        this.carregandoTabela = false;
        this.tabela.erroTabela = true;
      });
    },

    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy;
      this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";

      this.buscarDadosTabela();
    },

    lidarComParametrosBusca() {
      return {
        "termo": this.busca.termo,
        "colunaNome": this.tabela.campoOrdenado,
        "colunaOrdem": this.tabela.ordem,
        "perPage": this.dadosPaginacao.quantidadeLinhasPadrao,
        "page": this.dadosPaginacao.paginaAtual
      }
    },

    limparFiltros() {
      this.busca.termo = '';
      this.mostrarTabela = false;
      this.limparPaginacao();
    },

    limparPaginacao() {
      this.dadosPaginacao = {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      };
    },

    IniciarPaginacao(dadosPaginacao) {
      this.dadosPaginacao.daLinha = dadosPaginacao.from;
      this.dadosPaginacao.ateALinha = dadosPaginacao.to;
      this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
      this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
      this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
    },

    atualizarPaginaAtual(page) {
      this.dadosPaginacao.paginaAtual = page;
      this.buscarDadosTabela()
    },

    atualizaQuantidadeVisualizar(event) {
      if(!event) {
        this.dadosPaginacao.quantidadeLinhasPadrao = 10;
      }
      this.dadosPaginacao.paginaAtual = 1;
      this.buscarDadosTabela();
    },

    mudarStatus(rowItem){
      if(rowItem.situacao === 'Ativo'){
        modalGenericModel(
          'Inativar',
          'Ao invativar o questionário você o desabilitará para novas aplicações. Aplicações já criadas e em andamentos não serão afetadas. Deseja continuar?',
          require('@/assets/custom-icons/cora-icons/warning.png'),
          'Continuar',
          'Cancelar'
        ).then((result) => {
            if (result.isConfirmed) {
              this.atualizarStatusQuestionario(rowItem);
            }else{
              this.loadingPage = true;
              this.tabela.items = [];
              this.buscarDadosTabela();
            }
          });
      }
      if(rowItem.situacao === 'Inativo'){
        modalGenericModel(
          'Ativar',
          'Ao ativar o questionário você o habilitará para o uso em aplicações. Deseja continuar?',
          require('@/assets/custom-icons/cora-icons/success.png'),
          'Continuar',
          'Cancelar'
        ).then((result) => {
          if (result.isConfirmed) {
            this.atualizarStatusQuestionario(rowItem);
          }else{
            this.loadingPage = true;
            this.tabela.items = [];
            this.buscarDadosTabela();
          }
        });
      }
    },

    async atualizarStatusQuestionario(rowItem)
    {
      this.loadingPage = true;

      let index = this.tabela.items.findIndex((element) => element.id_questionario === rowItem.id_questionario);
      let status = '';
      if(rowItem.situacao === 'Ativo'){
        status = 'Inativo';
        this.tabela.items[index].situacao = status;
      }else{
        status = 'Ativo';
        this.tabela.items[index].situacao = status;
      }
      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        this.$api.questionario.atualizaStatus,
        {
          "{idQuestionario}": this.tabela.items[index].id_questionario,
          "{novoStatus}": status,
        }
      );
      await this.$http.put(rota)
        .then()
        .catch((error) => {
          modalGenericModel(
            error.response.data.error,
            '',
            require('@/assets/custom-icons/cora-icons/warning-red-icon.png'),
            'Entendi'
          )
          if(rowItem.situacao === 'Ativo'){
            rowItem.situacao = 'Inativo';
          }else{
            rowItem.situacao = 'Ativo';
          }
      });

      this.loadingPage = false;
    },

    handleModalRascunho(){
      modalGenericModel(
        'Rascunho',
        'Este questionário é um rascunho. Você precisa salvar e habilitar para uso antes de continuar',
        '',
        'Entendi'
      )
    },
  }
}
</script>
<style scoped>
.margin-right-custom{
  margin-right: 1rem;
}
@media (max-width: 1390px) {
  .margin-right-custom{
    margin-right: 0.1rem;
  }
}

.btn-acao-tabela {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.9rem !important;
  padding: 0.2rem !important;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .btn-acao-tabela {
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem !important;
    padding: 0.15rem !important;
  }
}
@media (max-width: 767px) {
  .btn-acao-tabela {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.7rem !important;
    padding: 0.1rem !important;
  }
}
.column-acoes {
  min-width: 8rem
}
@media (min-width: 768px) and (max-width: 1199px) {
  .column-acoes {
    min-width: 7rem
  }
}
@media (max-width: 767px) {
  .column-acoes {
    min-width: 6rem
  }
}
.btn-outline-blue {
  border-color: #2772C0!important;
  color: #2772C0!important;
  background-color: white!important;
}
.btn-outline-blue:hover {
  background-color: rgba(0, 137, 235, 0.05) !important;
  box-shadow: none!important;
}
.btn-outline-blue:focus {
  background-color: rgba(0, 137, 235, 0.05) !important;
  box-shadow: none!important;
}
.mr-1-variant {
  margin-right: 0.2rem !important;
}
.mt-n1-variant {
  margin-top: -0.2rem !important;
}
.table thead th {
  vertical-align: middle !important;
}
@media (max-width: 768px) {
  .header-title {
    font-size: 1.25em!important;
  }
}
@media (min-width: 769px) {
  .header-title {
    font-size: 1.5em!important;
  }
}
.custom-switch {
  padding-left: 2.25rem;
  padding-bottom: 1rem;
}
.custom-control-label-switch {
  padding-top: 0.5rem;
  padding-bottom: 0.1rem;
}
.custom-switch::v-deep .custom-control-label::before {
  left: -2.25rem;
  height: 2rem;
  width: 6rem;
  pointer-events: all;
  border-radius: 1rem;
}
.custom-switch::v-deep .custom-control-label::after {
  top: 0.125rem;
  left: -2rem;
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  background-color: #adb5bd;
  border-radius: 2rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch::v-deep .custom-control-label::after {
    transition: none;
  }
}
.custom-switch::v-deep .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(1.5rem);
  transform: translateX(3.7rem);
}
.switch-label-ativo{
  top: 0;
  margin-top: 2px;
  right: 0;
  padding-right: 30px;
  margin-left: 0;
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  color: #FFFFFF;
}
.switch-label-inativo{
  top: 0;
  margin-top: 3px;
  right: 0;
  margin-right: 1px;
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
}
.label-rascunho{
  color: #5E5873;
  background: #cccccc ;
  padding: 0.35rem 0.55rem;
  border-radius: 1rem;
}
.custom-text-modal-info{
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.313;
  letter-spacing: 0px;
  text-align: center;
}
.custom-title-modal-info{
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313;
  letter-spacing: 0px;
}
.cursor-na{
  cursor: not-allowed !important;
}

</style>
